import { Component, OnInit } from '@angular/core';
import { DatabaseService } from '../services/database.service';
import { Subscription } from 'rxjs';
import { Character } from '../models/character';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'rds-drink-up',
  templateUrl: './drink-up.component.html',
  styleUrls: ['./drink-up.component.scss']
})
export class DrinkUpComponent implements OnInit {

  // TODO: watch display size to determine how elements appear
  desktopDisplay: boolean = true;

  characters: Character[] = null;

  charactersSubscription: Subscription;

  constructor(private databaseService: DatabaseService) { }

  ngOnInit() {
    //Recover characters
    this.charactersSubscription = this.databaseService.charactersSubject.subscribe((characters:Character[]) => {
      characters = characters.map((char, index) => Object.assign({},{...char, id: index}));
      characters.sort((a,b) => a.name.localeCompare(b.name));
      this.characters = characters;
    });
    this.databaseService.getCharacters();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.characters, event.previousIndex, event.currentIndex);
  }

  ngOnDestroy() {
    this.charactersSubscription.unsubscribe();
  }

}
