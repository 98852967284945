import { ChangeDetectorRef, Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { DatabaseService } from './services/database.service';
import { CountdownComponent, CountdownConfig } from 'ngx-countdown';

@Component({
  selector: 'rds-root',
  templateUrl: './rds.component.html',
  styleUrls: ['./rds.component.scss']
})
export class RdsComponent {

  // Countdown timer variables
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;
  countdownConfig: CountdownConfig = {leftTime: 60*3600*24, format: 'dd:hh:mm:ss', demand: false};
  isSiteLive = true;

  // Used for mobile display
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, private databaseService: DatabaseService){
    this.mobileQuery = media.matchMedia('(max-width: 640px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit(){
    // Recover time left from database
    this.databaseService.getFinalDate().then(date => {
      const currentDate = new Date();
      const liveDate = new Date(date);
      const leftTime = (liveDate.getTime() - currentDate.getTime()) / 1000;

      this.countdownConfig = {leftTime: leftTime, format: 'dd:hh:mm:ss', demand: false};
      this.countdown.restart();
      this.countdown.begin();
    });
  }

  handleCountdownEvent(event){
    if(event.action === "done"){
      this.isSiteLive = true;
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
}
