import { Component, OnInit, OnDestroy } from '@angular/core';
import { Character } from '../models/character';
import { DatabaseService } from '../services/database.service';
import { Subscription } from 'rxjs';

import {characters} from '../../../personnages';

@Component({
  selector: 'rds-characters',
  templateUrl: './characters.component.html',
  styleUrls: ['./characters.component.scss']
})
export class CharactersComponent implements OnInit {

  characters: Character[] = null;

  charactersSubscription: Subscription;

  constructor(
    private databaseService: DatabaseService
  ) { }

  ngOnInit() {
    //Recover characters
    this.charactersSubscription = this.databaseService.charactersSubject.subscribe((characters:Character[]) => {
      characters = characters.map((character, index) => Object.assign({},{...character, id: index}));
      characters.sort((a,b) => a.name.localeCompare(b.name));
      this.characters = characters;
    });
    this.databaseService.getCharacters();
  }

  ngOnDestroy() {
    this.charactersSubscription.unsubscribe();
  }

}
