import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { Character } from '../models/character';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  characters: Array<Character> = null;
  charactersSubject = new Subject<Array<Character>>();

  database = null;

  constructor() {
    // Firebase configuration
    var firebaseConfig = {
      apiKey: "AIzaSyDvbSQ3yXHZa5cPwPIJQRZ18YnbIhUdjEw",
      authDomain: "resoidusoleil.firebaseapp.com",
      databaseURL: "https://resoidusoleil.firebaseio.com",
      projectId: "resoidusoleil",
      storageBucket: "resoidusoleil.appspot.com",
      messagingSenderId: "111138730299",
      appId: "1:111138730299:web:0e91ac43f3412dce16c71e"
    };
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);

    // Recover database reference object
    this.database = firebase.database();
  }

  emitCharacters(){
    this.charactersSubject.next(this.characters);
  }

  /**
   * Saves a character in database
   *
   * @param characters The characters list object data to save in database
   */
  saveCharacters(characters: Character[]){
    return this.database.ref('characters/').set(characters);
  }

  /**
   * Returns the characters stored in database
   */
  getCharacters(){
    this.database.ref('characters').on('value', characters => {
      this.characters = (characters && characters.val()) || [];
      this.emitCharacters();
    });
  }

  /**
   * Returns a specific character based on its ID
   * @param id id number of the character to get
   */
  getCharacter(id: string){
    return new Promise<Character>((resolve,reject) => {
      this.database
        .ref(`characters/${id}`)
        .once('value')
        .then(character => resolve(character.val()))
        .catch(error => reject(error))
    });
  }

  /**
   * Returns the final date for countdown management
   */
  getFinalDate(){
    return new Promise<string>((resolve,reject) => {
      this.database
        .ref(`finalDate`)
        .once('value')
        .then(date => resolve(date.val()))
        .catch(error => reject(error))
    });
  }
}
