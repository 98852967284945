import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rds-places',
  templateUrl: './places.component.html',
  styleUrls: ['./places.component.css']
})
export class PlacesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
