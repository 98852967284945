import { Component, OnInit } from '@angular/core';
import { Character } from '../../models/character';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { DatabaseService } from 'src/app/services/database.service';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'rds-character-details',
  templateUrl: './character-details.component.html',
  styleUrls: ['./character-details.component.scss']
})
export class CharacterDetailsComponent implements OnInit {

  character: Character = null;

  constructor(
    private route: ActivatedRoute,
    private databaseService: DatabaseService
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.databaseService.getCharacter(params.get('id'))
        .then(char => this.character = char)
    });
  }

}
