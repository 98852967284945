import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { RdsComponent } from './rds.component';
import { HomeComponent } from './home/home.component';

import { CharactersComponent } from './characters/characters.component';
import { AppRoutingModule } from './app-routing.module';
import { PlacesComponent } from './places/places.component';
import { CharacterCardComponent } from './characters/character-card/character-card.component';
import { CharacterDetailsComponent } from './characters/character-details/character-details.component';
import { DrinkUpComponent } from './drink-up/drink-up.component';

import { CountdownModule } from 'ngx-countdown';

@NgModule({
  declarations: [
    RdsComponent,
    HomeComponent,
    CharactersComponent,
    PlacesComponent,
    CharacterCardComponent,
    CharacterDetailsComponent,
    DrinkUpComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    AppRoutingModule,
    MatChipsModule,
    MatBadgeModule,
    MatDividerModule,
    MatTooltipModule,
    MatListModule,
    CountdownModule,
    DragDropModule
  ],
  providers: [],
  bootstrap: [RdsComponent]
})
export class RdsModule { }
